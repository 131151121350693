import GlobalStyle from 'src/globalStyles';
import type { AppProps } from 'next/app';

import theme from 'src/components/themes/DefaultTheme';
import { envConfig } from 'src/lib/client/envConfig';
import QueryClientWrapper from 'src/lib/contexts/QueryClientWrapper';
import UserWrapper from 'src/lib/contexts/UserContext';
import DefaultLayout from "src/lib/pages/DefaultLayout";
import type { ICommonAppData } from 'src/lib/types/ICommonAppData';
import type ISettings from 'src/lib/types/Settings';
import { NextPageWithLayout } from 'src/lib/types/next/NextPageWithLayout';
import Head from 'next/head';
import { Router } from 'next/router';
import Script from 'next/script';
import { ReactElement, ReactNode } from 'react';
import NextTopLoader from 'nextjs-toploader';

//#region [Props]
type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
	pageProps: {
		appData: ICommonAppData;
		dehydratedState?: unknown;
		robots: string;
		envName: string;
	};
}

//#endregion

//#region [Component]
export default function App({ Component, pageProps, router }: AppPropsWithLayout) {
	// this is for per-page layout support.
	const getLayout = Component.getLayout ?? getDefaultLayout;

	return <>
		<Head>


			<meta key="ogTitle" property="og:title" content={envConfig.NEXT_PUBLIC_DEFAULT_TITLE} />
			<meta key="ogDescription" property="og:description" content={envConfig.NEXT_PUBLIC_DEFAULT_DESCRIPTION} />
			<meta key="metaDescription" name="description" content={envConfig.NEXT_PUBLIC_DEFAULT_DESCRIPTION} />
			<meta key="metaTitle" name="title" content={envConfig.NEXT_PUBLIC_DEFAULT_TITLE} />
			<title key="title">{`${envConfig.NEXT_PUBLIC_DEFAULT_TITLE} - Filmweb`}</title>


			<meta property="fb:app_id" content={envConfig.NEXT_PUBLIC_FB_APPID} />
			<meta property="og:locale" content="nb_NO" />
			<meta key="ogType" property="og:type" content="website" />
			<meta property="og:site_name" content="filmweb.no" />
			<meta name="format-detection" content="telephone=no" />
			<meta name="google-site-verification" content={envConfig.NEXT_PUBLIC_GOOGLE_SITE_VER} />
			<meta name="copyright" content="Filmweb AS" />
			<meta name="authors" content="Filmweb AS" />
			<meta name="theme-color" content={theme.themeColor} />
			<meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="mobile-web-app-capable" content="yes" />
			<meta name="distribution" content="Global" />
			<meta name="robots" key="robots" content={pageProps.robots} />


			<link rel="apple-touch-startup-image" href="/faviconsandroid-chrome-512x512.png" />

			<link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
			<link rel="icon" type="image/png" href={`/favicons/favicon-32x32${pageProps.envName}.png`} sizes="32x32" />
			<link rel="icon" type="image/png" href={`/favicons/favicon-16x16${pageProps.envName}.png`} sizes="16x16" />
			<link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#5bbad5" />
			<meta name="theme-color" content="#690ab8" />
			<link rel="manifest" href="/manifest.json" />


			<link href="https://cdn.adnuntius.com" rel="preconnect" crossOrigin='anonymous' />
		</Head>
		<Script id="google-tag-manager" strategy='afterInteractive'>
			{`
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','GTM-5JS34TL');
			`}
		</Script>
		{/*<Script id="docDomain" >{`document.domain ="filmweb.no";`}</Script>*/}
		<GlobalStyle />
        <NextTopLoader/>
		<QueryClientWrapper dehydratedState={pageProps.dehydratedState}>
			<UserWrapper>
				{getLayout(<Component {...pageProps} />, router, (pageProps?.appData as (ICommonAppData | undefined))?.settings)}
			</UserWrapper>
		</QueryClientWrapper>
	</>;
}
//#endregion

//#region [Other]
function getDefaultLayout(page: ReactElement, router: Router, appSettings?: ISettings): ReactNode {
	return <DefaultLayout appSettings={appSettings}>{page}</DefaultLayout>
}

//#endregion